<template>
    <div>
        <div>
            <Breadcrumb content="编辑产品"/>
        </div>
        <div class="m-bg m-pd">
            <el-form v-model="form" label-width="100px" >
                <el-row>
                    <el-col :span="10" :offset="1">
                        <el-form-item label="类型">
                            <el-select v-model="form.type" class="m-full">
                                <el-option
                                        v-for="(v,index) in $webConfig.productTypes"
                                        :key="index"
                                        :label="v.name"
                                        :value="v.type"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="名称">
                            <el-input v-model="form.name"></el-input>
                        </el-form-item>
                        <el-form-item label="编号">
                            <el-input v-model="form.no"></el-input>
                        </el-form-item>
                        <el-form-item label="建议零售价">
                            <el-input type="textarea" :rows="5" v-model="form.steer_sale"></el-input>
                        </el-form-item>
                        <el-form-item label="发售日期">
                            <el-input v-model="form.sale"></el-input>
                        </el-form-item>
                        <el-form-item label="卡种数量">
                            <el-input type="textarea" :rows="5" v-model="form.style"></el-input>
                        </el-form-item>
                        <el-form-item label="国家/集团">
                            <el-select v-model="form.select_n_g">
                                <el-option label="国家" value="nation"></el-option>
                                <el-option label="集团" value="group"></el-option>
                            </el-select>
                            <el-input v-show="form.select_n_g == 'nation'" type="textarea" :rows="5" v-model="form.nation"></el-input>
                            <el-input v-show="form.select_n_g == 'group'" type="textarea" :rows="5" v-model="form.group"></el-input>
                        </el-form-item>
                        <el-form-item label="活动">
                            <el-select v-model="form.activity_show">
                                <el-option label="显示" value="1"></el-option>
                                <el-option label="隐藏" value="0"></el-option>
                            </el-select>
                            <el-input type="textarea" :rows="5" v-model="form.activity"></el-input>
                        </el-form-item>
                        <el-form-item label="视频">
                            <el-select v-model="form.video_show">
                                <el-option label="显示" value="1"></el-option>
                                <el-option label="隐藏" value="0"></el-option>
                            </el-select>
                            <el-input type="textarea" :rows="5" v-model="form.video"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="10">
                        <el-form-item label="图片">
                            <AvatarUpload v-if="form.id>0" :outImgUrl="form.img" @uploadSuccess="handleBanner"></AvatarUpload>
                        </el-form-item>
                        <el-form-item label="相关产品">
                            <el-button type="success" @click="dialogFormVisible=true">关联产品</el-button>
                            <el-row v-for="(item,index) in selectList" :key="item.id" style="margin-bottom: 5px">
                                <el-col :span="8">名称:{{item.name}}</el-col>
                                <el-col :span="8">编号:{{item.no}}</el-col>
                                <el-col :span="8">
                                    <el-button type="danger" @click="delSelect(index)">删除</el-button>
                                </el-col>
                            </el-row>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="18" :offset="1">
                        <el-form-item label="内容">
                            <div style="height: 500px">
                                <Editor v-if="form.id>0" @getEditorVal="getEditorVal" :editorVal="form.content"></Editor>
                            </div>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="success" @click="submit">立即保存</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </div>
        <div>
            <el-dialog title="选择相关产品" :visible.sync="dialogFormVisible" >
                <SelectList @selectRowSuccess="revRow"></SelectList>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    import Breadcrumb from '@/components/admin/breadcrumb'
    import Editor from '@/components/admin/editor'
    import AvatarUpload from "@/components/admin/avatar-upload"
    import SelectList from "./components/select-list"

    export default {
        name: "edit",
        components:{Breadcrumb,Editor,AvatarUpload,SelectList},
        data() {
            return {
                form: {
                    id:"",
                    type:1,
                    name:"",
                    no:"",
                    img:"",
                    sale:"",
                    steer_sale:"",
                    nation:"",
                    group:"",
                    card_category:"",
                    style:"",
                    activity:"",
                    activity_show:"1",
                    video:"",
                    video_show:"1",
                    content:"",
                    other_product:"",
                    select_n_g:"nation",
                },
                selectList:[],
                dialogFormVisible:false,
            }
        },
        methods: {
            revRow(obj){
                // 已存在的不能添加
                let isExists = false;
                this.selectList.forEach((v)=>{
                    if (v.id == obj.id){
                        isExists = true;
                    }
                });
                if (!isExists){
                    this.selectList.push(obj)
                }
            },
            handleBanner(callback) {
                this.form.img = callback.imgUrl;
            },
            getEditorVal(val){
                this.form.content = val;
            },
            submit(){
                // 拼接ID
                this.form.other_product= "";
                this.selectList.forEach((v)=>{
                    this.form.other_product += v.id+","
                });
                this.$axios.post("site/siteProduct/saveProduct",this.form)
                    .then((res)=>{
                        if (res.data.code == 1000){
                            this.$message.success("保存成功");
                            setTimeout(()=>{
                                this.$router.push("/admin/products");
                            },1000)
                        }else{
                            this.$message.error(res.data.msg)
                        }
                    })
            },
            delSelect(index){
                this.selectList.splice(index,1)
            },
            getInfo(){
                let id = this.$route.query.id;
                this.$axios.post("site/siteProduct/getInfo",{id})
                    .then((res)=>{
                        if (res.data.code == 1000){
                            this.selectList = res.data.data.otherProducts;
                            this.form.type = res.data.data.type ;
                            this.form.name = res.data.data.name ;
                            this.form.no = res.data.data.no ;
                            this.form.img = res.data.data.img ;
                            this.form.sale = res.data.data.sale ;
                            this.form.steer_sale = res.data.data.steer_sale ;
                            this.form.nation = res.data.data.nation ;
                            this.form.group = res.data.data.group ;
                            this.form.card_category = res.data.data.card_category ;
                            this.form.style = res.data.data.style ;
                            this.form.activity = res.data.data.activity ;
                            this.form.activity_show = res.data.data.activity_show +"";
                            this.form.video = res.data.data.video ;
                            this.form.video_show = res.data.data.video_show +"";
                            this.form.content = res.data.data.content ;
                            this.form.other_product = res.data.data.other_product;
                            this.form.select_n_g = res.data.data.select_n_g;
                            this.form.id = res.data.data.id;
                        }else{
                            this.$message.error(res.data.msg)
                        }
                    })
            }
        },
        created() {
            this.getInfo();
        }
    }
</script>

<style scoped>

</style>
